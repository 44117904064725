import React from 'react';
import './AreAliensRealStyles.css';
import { GiAlienBug } from "react-icons/gi";



const AreAliensReal = () => {
    return  (
        <div className='arealiensreal'>
            <div className='container'>
                <div className='content'>
                    <h2><GiAlienBug className='iconmain' /><span> Are Aliens Real?</span> Yes or No</h2>
                    <p>
                    Book Cover Image with link to Amazon Ebook
                    </p>

                   
                    
                    
                </div>
            </div>

        </div>
    )
}

export default AreAliensReal;