
import React from 'react';
import Hero from './components/hero/Hero';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';


function App() {
  return (
    
    <>
    <Navbar />
    <Hero />
    <Footer />
    
    </>


        
      
  
  );
}

export default App;
