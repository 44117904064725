import React from 'react';
import './ObjectsStyles.css';
import { GiMoonOrbit } from "react-icons/gi";

export const Objects = () => {
    

    return  (
        <div className='objects'>
            <div className='container'>
            <div className='content'>
                    <h2><GiMoonOrbit className='iconmain' /><span> Object</span> Classes</h2>
                    <p>
                    Objects
                    </p>
                    
                    
                </div>
        </div>
        </div>
    );
};

export default Objects;