import React from 'react';
import './TermsStyles.css';
import { GiBlackHoleBolas } from "react-icons/gi";

export const Terms = () => {
    

    return  (
        <div className='terms'>
            <div className='container'>
            <div className='content'>
                    <h2><GiBlackHoleBolas className='iconmain' /><span> Space Term</span> Definitions</h2>
                    <p>
                    Terms
                    </p>
                    
                    
                </div>
        </div>
        </div>
    );
};

export default Terms;