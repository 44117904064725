import React from 'react';
import './SpaceTechStyles.css';
import { ImRocket } from "react-icons/im";

export const SpaceTech = () => {
    

    return  (
        <div className='spacetech'>
            <div className='container'>
            <div className='content'>
                    <h2><ImRocket className='iconmain' /><span> Space Technology</span> Levels</h2>
                    <p>
                    Tech
                    </p>
                    
                    
                </div>
        </div>
        </div>
    );
};

export default SpaceTech;